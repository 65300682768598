.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type='range']::-webkit-slider-thumb {
  background-color: #282c34 !important;
}

input[type='radio'] {
  accent-color: #282c34 !important;
}

.newHeroBg {
  background: url('./assets/newImages/heroBg.png') no-repeat center;
  background-position-x: 75%;
  min-height: 70vh;
  background-size: cover;
}

.heroLayout {
  align-items: center;
}

@media (max-width: 767.98px) {
  .newHeroBg {
    background: url('./assets/newImages/heroBg.png') no-repeat center;
    background-position-x: 75%;
    min-height: 70vh;
    background-size: cover;
  }

  .heroLayout {
    align-items: flex-start;
  }
}

.newInputStyle {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #D3D3D3;
  width: 100%;
  color: #000;
  font-weight: 500;
  height: 55px;
  outline: none;
  padding-inline: 20px;
  padding-left: 45px;
}



.newInputStyle::placeholder {
  color: #D3D3D3;
  font-weight: 400;
}



select:invalid {
  color: #D3D3D3 !important;
  font-size: 16px;
}

select option {
  color: #000 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 3px;
  text-overflow: '';
}

.textWithHypens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.disabledOpacity:disabled {
  opacity: 0.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}