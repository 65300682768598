.cloudsBackground {

    /* height: 350px; */
    width: 100%;
    background-repeat: repeat-x;
    background-size: contain;
}

@keyframes moveCloudsRight {
    0% {
        background-position: 0 center;
    }

    100% {
        background-position: 1920px center;
    }
}

.wrapDiv {
    margin: 0px 12rem;
}

.wrapDiv1 {
    padding: 0px 12rem;
}

.blueBackgroundDiv {
    background: url('../../assets/images/bluebackground.svg') no-repeat;
    background-size: cover;
}

.firstBlueTitle {
    color: #30313A;
    font-weight: 700;
}

.finanuSubTitle {
    font-size: 22px !important;
    color: #212529 !important;
    font-weight: 500;
}

.finanuSubTitleW {
    font-size: 22px !important;

    font-weight: 500;
}

.whiteTextOverwrite {
    color: #fff !important;
}

.firstSectionBlueBtn1 {
    color: #fff;
    background-color: #30313A;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    padding: 0.5rem 2.5rem;
}

.firstSectionBlueBtn {
    color: #30313A;
    background-color: #fff;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    padding: 0.5rem 2.5rem;
}

.blueBgdiv {
    background-color: #30313A;
    border-radius: 25px;
}

.blueBgdiv a {
    text-decoration: none !important;
}

.blueBgdiv img {
    width: 130px;
}

.servicesWhiteText {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
}



.whiteTestimonials {
    background-color: #fafafa;
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px;
}

.customSelectStyle {
    border: none !important;
    box-shadow: 0px 4px 15px rgba(193, 175, 175, 0.25) !important;
    border-radius: 8px !important;
    font-weight: 400 !important;

}

.fourthSectionBlueDiv {
    color: #fff;
    background-color: #30313A;
    border: none;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.customSelectStyle::placeholder {
    font-weight: 400 !important;
    color: #000 !important;
    text-align: center;
}

.blueCloudsBg {
    background-color: #30313A;
    background-image: url('../../assets/images/blueCloudsBg.svg');
    background-size: cover;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px;
}

.autoCheckbox {
    width: 420px !important;
}

.checkboxWidth {
    max-width: 420px !important;
    width: 100%;
}

.paddingTopDiv {
    padding-top: 9rem;
}

.autoImage {
    width: 50%;
}

.textStartToEnd {
    text-align: end;
}

.sideBlueBg {
    position: absolute;

    right: 0;
    background: url('../images/sideBlueBg.svg') no-repeat;
    background-size: cover;
    height: 1600px;
    width: 500px;
}

.fourthSectionImgDiv {
    border: 1px solid #30313A;
    border-radius: 12px 12px 0px 0px;
}

.autoSelectDropdown {
    left: calc(50% - 210px);
    top: 4.5rem !important;
}

.autoBtnStyle {
    padding: 0.75rem;
    border-radius: 12px;
    border: none;
    background-color: #fff;
    color: #fff;
    background-color: #11A3E6;
    font-weight: 500;
    width: 200px;
}

.blogArticleBorder {
    width: 1px;
    background-color: #C5C5C5;
    height: 100%;
}

.borderBottomArticle {
    border-bottom: 1px solid #C5C5C5;
}

.react-datepicker {
    border: none !important;
    box-shadow: 0px 4px 15px rgba(193, 175, 175, 0.25) !important;
    border-radius: 15px !important;
}

.react-datepicker__header {
    border-bottom: none !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    background-color: #f7f7f7 !important;
}

.react-datepicker__day-name {
    color: #a7a9ac !important;
}

.react-datepicker__triangle {
    display: none !important;
}

.react-datepicker__month {
    margin: 0.6rem !important;
}

.react-datepicker__header {
    padding: 0.9rem 0rem !important;

}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #30313A !important;
}

.react-datepicker__current-month {
    padding-bottom: 0.3rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #30313A !important;
    border-radius: 5px !important;
    color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #30313A !important;
    border-radius: 5px !important;
    color: #fff !important;
}

span.fs-1 {
    font-size: 52px !important;
    line-height: 1.2;
    font-weight: 400 !important;
}

.darkTheme .trustpilot-widget {
    color: #fff !important;
}

div.fs-1 {
    font-size: 33px !important;
}

span.fs-2 {
    font-size: 28px !important;
}

div.fs-2 {
    font-size: 28px !important;
}

span.fs-3 {
    font-size: 26px !important;
}

div.fs-3 {
    font-size: 26px !important;
}

span.fs-4 {
    font-size: 24px !important;
}

div.fs-4 {
    font-size: 24px !important;
}

span.fs-5 {
    font-size: 20px !important;
}

div.fs-5 {
    font-size: 20px !important;
}

.firstBlueTitle {
    font-size: 33px;
}

.blogSwiper .swiper-wrapper {
    padding-bottom: 2rem
}

.blogSwiper .swiper-pagination-bullet-active {
    background-color: #30313A;
}



@media (max-width: 1399.98px) {
    .wrapDiv {
        margin: 0px 6rem;
    }

    .wrapDiv1 {
        padding: 0px 6rem;
    }

    span.fs-1 {
        font-size: 30px !important;
    }

    div.fs-1 {
        font-size: 30px !important;
    }

    span.fs-2 {
        font-size: 28px !important;
    }

    div.fs-2 {
        font-size: 28px !important;
    }

    span.fs-3 {
        font-size: 26px !important;
    }

    div.fs-3 {
        font-size: 26px !important;
    }

    span.fs-4 {
        font-size: 24px !important;
    }

    div.fs-4 {
        font-size: 24px !important;
    }

    span.fs-5 {
        font-size: 18px !important;
    }

    div.fs-5 {
        font-size: 18px !important;
    }

    .firstBlueTitle {
        font-size: 33px;
    }
}

@media (min-width: 575.98px) and (max-width: 1500.98px) {

    span.fs-1 {
        font-size: 30px !important;
    }

    div.fs-1 {
        font-size: 30px !important;
    }

    span.fs-2 {
        font-size: 28px !important;
    }

    div.fs-2 {
        font-size: 28px !important;
    }

    span.fs-3 {
        font-size: 26px !important;
    }

    div.fs-3 {
        font-size: 26px !important;
    }

    span.fs-4 {
        font-size: 24px !important;
    }

    div.fs-4 {
        font-size: 24px !important;
    }

    span.fs-5 {
        font-size: 18px !important;
    }

    div.fs-5 {
        font-size: 18px !important;
    }

    .firstBlueTitle {
        font-size: 35px;
    }
}


@media (max-width: 1199.98px) {
    .wrapDiv {
        margin: 0px 2.5rem;
    }

    .wrapDiv1 {
        padding: 0px 2.5rem;
    }

    .paddingTopDiv {
        padding-top: 9rem;
    }

    .firstBlueTitle {
        font-size: 35px;
    }

}

@media (max-width: 991.98px) {
    .wrapDiv {
        margin: 0px 1.5rem;
    }

    .wrapDiv1 {
        padding: 0px 1.5rem;
    }

    /* .cloudsBackground {
        height: 250px;
    } */

    .autoImage {
        width: 60%;
    }

    .autoCheckbox {
        width: 100% !important;
    }

    .autoThirdStep .autoCheckbox {
        width: 250px !important;
    }

    .autoSelectDropdown {
        left: 0;
    }


    span.fs-1 {
        font-size: 32px !important;
    }

    div.fs-1 {
        font-size: 32px !important;
    }

    span.fs-2 {
        font-size: 24px !important;
    }

    div.fs-2 {
        font-size: 24px !important;
    }

    span.fs-3 {
        font-size: 23px !important;
    }

    div.fs-3 {
        font-size: 23px !important;
    }

    span.fs-4 {
        font-size: 21px !important;
    }

    div.fs-4 {
        font-size: 21px !important;
    }

    span.fs-5 {
        font-size: 18px !important;
    }

    div.fs-5 {
        font-size: 18px !important;
    }

    .firstBlueTitle {
        font-size: 30px;
    }
}

@media (max-width: 767.98px) {
    .blogArticleBorder {
        width: auto;
        height: 1px;
    }

    .cloudsBackground {
        background-size: cover;
        height: auto;
    }

    .wrapDiv {
        margin: 0rem 1.5rem;
    }

    .wrapDiv1 {
        padding: 0rem 1.5rem;
    }

  

    .bodyCounterPadding {
        padding-bottom: 6rem;

    }



    .paddingTopDiv {
        padding-top: 8rem;
    }

    .autoImage {
        width: 60%;
    }

}

@media (max-width: 575.98px) {

    .rechNextBtnStyle {
        width: 100%;
    }

    .fourthSectionImg {
        width: 100%;
    }

    .autoCheckbox {
        width: 100% !important;
    }

    .autoThirdStep .autoCheckbox {
        width: 100% !important;
    }

    .autoImage {
        width: 100%;
    }

    .checkboxWidth {
        max-width: 100% !important;
        width: 100%;
    }

    .textStartToEnd {
        text-align: start;
    }

    .firstBlueTitle {
        font-size: 30px;
    }

    .finanuSubTitle {
        font-size: 18px !important;
    }

    span.fs-1 {
        font-size: 40px !important;
    }

    div.fs-1 {
        font-size: 40px !important;
    }

    span.fs-2 {
        font-size: 23px !important;
    }

    div.fs-2 {
        font-size: 23px !important;
    }

    span.fs-3 {
        font-size: 22px !important;
    }

    div.fs-3 {
        font-size: 22px !important;
    }

    span.fs-4 {
        font-size: 20px !important;
    }

    div.fs-4 {
        font-size: 20px !important;
    }

    span.fs-5 {
        font-size: 16px !important;
    }

    div.fs-5 {
        font-size: 16px !important;
    }
}

.darkTheme .cloudsBackground span {
    color: #30313A;
}

.darkTheme .blueBgdiv {
    background-color: #333436;
}

.darkTheme .normalTextToBlue {
    color: #30313A;
}

.darkTheme .normalTextToWhite {
    color: #fff !important;
}

.darkTheme .blueCloudsBg {
    background-color: #333436;
    background-image: url('../../assets/images/blueCloudsBgDark.svg');

}

.darkTheme .fourthSectionBlueDiv {
    background-color: #333436;
}

.darkTheme .whiteTestimonials {
    background-color: #333436;
    color: #fff;
}

.darkTheme .blueBackgroundDiv {
    background: url('../../assets/images/bluebackgroundDark.svg') no-repeat;
    background-size: cover;
}

.darkTheme .subtitleofwettbewerbe {
    color: #fff;

}

.darkTheme .tp-widget-empty-horizontal span {
    color: #fff !important;
}