.blueBg {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.paddingoftherowofvorar {
    padding-bottom: 3.5rem;
}

.plusTitle {
    font-size: 65px;
    line-height: 65px;
}

.secondaryColor {
    color: #5a5a5a;
}

.triaryColor {
    color: #30313A;
}

.plusBtn {
    background: #30313A;
    border-radius: 15px;
    border: none;
    color: #fff;
    padding: 15px 0px;
    max-width: 400px;
}

.plusForm {
    background: #30313A;
    border-radius: 40px;
    width: 100%;
    max-width: 630px;
    position: relative;
}

.collapsofthelastcomponent {
    color: white;
}

.lastcomponentbutton {
    color: #FFFFFF;
    border: solid 0.3px #FFFFFF;
    background-color: #30313A;
    border-radius: 15px !important;
    width: 450px !important;
}

.lastcomponentbutton2 {
    color: #FFFFFF;
    border: solid 0.3px #FFFFFF;
    background-color: #30313A;
    border-radius: 15px !important;
    width: 350px !important;
}

.plusFormTitle {
    line-height: 142.9%;
    color: rgba(255, 255, 255, 0.54);
}

.plusInput {
    border: 1px solid #D5D5D5;
    border-radius: 11px !important;
    width: 100%;
    background-color: white !important;
    box-shadow: none !important;
}

.plusInput::placeholder {
    font-weight: 400 !important;
    color: rgba(112, 112, 112, 0.54) !important;

}

.plusInput {
    font-weight: 500 !important;
}

.plusSelectArrow {
    background-image: url('../images/plus/selectArrow.svg') !important;
    background-position: right 1rem center !important;
    background-size: 20px 15px !important;
}

.formIluDiv {
    position: absolute;
    bottom: -12px;
    left: 1.5rem;
    width: 100%;
    max-width: 339.31px;
    width: 339.31px;
}



.finanuWhiteBtn {
    background: #FFFFFF;
    border-radius: 8px;
    font-weight: 700;
    color: #30313A;
    border: none;
}

.finanuWhiteBtn a {
    text-decoration: none;
    color: #30313A;

}

.darkTheme .finanuWhiteBtn {
    background: #30313A;
    color: #FFFFFF;

}

.darkTheme .finanuWhiteBtn a {
    color: #FFFFFF;

}

.darkTheme .secondaryColor {
    color: #fff;
}

.plusSliderBackground {
    background: rgba(190, 232, 255, 0.5);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    height: 600px;
}

.sliderTabs {
    position: absolute;
    top: 0;

}

.plusSliderButton {
    border-radius: 4px;
    padding: 0.25rem 0rem;
    cursor: pointer;
    width: 100px;
    background: linear-gradient(to right, #30313A 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.sliderBtnEffect {
    -webkit-transition: linear 4s;
    -moz-transition: linear 4s;
    transition: linear 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: unset;
    background-position: left bottom;
}

.plusReviewsDiv {
    background: #FFFFFF;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px;
    border-radius: 25px;
}

.plusLink {
    color: #30313A;
    font-weight: 700;
    font-size: 18px;
}

.plusCoverDiv {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    height: 100%;
}

.selectofangebote {
    background: #FFFFFF;
    border-radius: 9px !important;
    border: none !important;
    font-weight: 700;
    color: #5A5A5A !important;

}

.darkTheme .textstyleofselect {
    color: white
}

.darkTheme .selectofltblue {
    background-color: transparent !important;
    border: 1px solid #11A3E6 !important;
    color: #fff;
}

.cardofthelastcomponent {
    max-width: 339px;
    height: 330px;
    background: #30313A;
    border-radius: 25px;
    display: flex;
    align-items: center;
    color: white;
}

.buttonofthelastcomponent {
    color: #FFFFFF;
    background-color: transparent;

}

.buttonofthelastcomponent button {
    background: #30313A;
    color: white;
}

.buttonmeineempfehlung {
    border: 0.5px solid white !important;
    background-color: transparent;
    color: #FFFFFF;
}

.accordionmainstyle .accordion-item:first-of-type {
    border-top-left-radius: calc(10px - 1px) !important;
    border-top-right-radius: calc(10px - 1px) !important;
}

.accordion-button::after {
    background-image: url('../images/plus/accordionimg.svg') !important;

}


.ltBlueSection {
    background: #E7F5FC;
    border-radius: 27px;
}

/* .topBottomBordersDiv {
    border-top: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;

} */
.containerFinPlus {
    display: block;
    position: relative;
    padding-left: 40px;
    margin-bottom: 44px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkmarkFinPlus {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
}

.containerFinPlus input:checked~.checkmarkFinPlus {
    background-color: #fff;
}

.checkmarkFinPlus:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerFinPlus input:checked~.checkmarkFinPlus:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerFinPlus .checkmarkFinPlus:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 14px;
    border: solid #30313A;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (min-width: 2399.98px) {
    .videobackground {
        background: #30313A;
        border-radius: 14px;
        max-width: 900px;
        margin-left: 25px;
        margin-bottom: 25px;
    }

    .videoinsecondsection {

        margin-left: -25px;
        margin-bottom: -25px
    }
}

@media (max-width: 2399.98px) {
    .videobackground {
        background: #30313A;
        border-radius: 14px;
        max-width: 900px;
        margin-left: 25px;
        margin-bottom: 25px;
    }

    .videoinsecondsection {

        margin-left: -25px;
        margin-bottom: -25px
    }

    .plusFormBtnDiv {
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;

    }
}

@media (max-width: 1399.98px) {
    .plusTitle {
        font-size: 45px;
    }

}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
    .plusForm {
        max-width: unset;
    }

    .plusSliderBackground {
        height: 500px;
    }
}

@media (max-width: 767.98px) {
    .plusSliderBackground {
        height: 500px;
    }

    .signatureRow {
        justify-content: center !important;
    }
}

@media (max-width: 575.98px) {
    .videobackground {
        margin-right: 10px;
        margin-left: 10px;
    }

    .signaturephoto {
        width: 140px;
        text-align: center;
    }

    .signaturediv {
        text-align: center;
    }

    .videoinsecondsection {
        margin-right: -25px;
        margin-bottom: -25px;
        margin-left: 0px
    }

    .styleofplustabsmobile {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }

    .mobiletabstitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #5A5A5A;
        justify-content: center;
    }

    .faqstyle {
        background: #E7F5FC !important;
        border-radius: 10px;
        color: #30313A !important;
    }

    .accordionstyle {
        border: none !important;
    }


    .faqstyle button {
        background: #E7F5FC !important;
        border-radius: 10px !important;
        color: #30313A !important;

    }

    .agilestyleofaccordion button {
        background-color: #30313A;
        color: white;
        border: none !important
    }

    .bgcoloroflastcomponent {
        background-color: #E7F5FC;
    }

    .agilestyleofaccordion .accordion-item {
        border: none !important;
        background-color: #30313A;

    }

    .mobiletabssubtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #5A5A5A;

    }

    .contentoflastaccordion {
        color: black !important;
        background-color: #30313A !important;
    }

    .buttonofthelastcomponent button {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .formIluDiv {
        max-width: 300px;
        left: -10px;
        position: absolute;
        bottom: -15px;
        width: 100%;
        max-width: 339.31px;
        width: 339.31px;
    }

    .styleofplustabsmobile {

        height: 301px;
        box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.12);
        border-radius: 25px;

    }

    .plusForm {
        border-radius: 25px;
    }

    .blueBg {
        position: relative;
        background-color: #30313A;
    }

    .plusSliderBackground {
        padding: 0rem 1.5rem;
    }

    .plusTitle {
        font-size: 35px;
    }

    .plusLink {
        font-size: 14px;
    }

    .plusBtn {
        font-size: 14px;
        max-width: 150px;
    }

    .darkTheme .blueBg {
        background-color: #333436;
    }
}

.buttonofthelastcomponent button:not(.collapsed) {
    background: #30313A;
    color: white;
}


.accordion-item:first-child .accordion-button {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;

}

.accordion-item:last-child .accordion-button {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}


.accordion-item:last-child:not(.collapsed) {
    border-radius: 0px;
}

.darkTheme .videobackground {
    background-color: #333436;
}


.darkTheme .plusSliderBackground {
    background-color: #333436;
}

.darkTheme .plusForm {
    background-color: #333436;
}

.darkTheme .plusReviewsDiv {
    background-color: #333436;
    color: white;
    box-shadow: none;
}

.darkTheme .plusCoverDiv {
    background-color: #333436;

}

.darkTheme .ltBlueSection {
    background-color: #333436;

}

.darkTheme .accordionstyle {
    background-color: #333436;
    color: white;
}

.darkTheme .accordionstyle .faqstyle {
    background-color: #333436 !important;
    color: white;
}

.darkTheme .faqstyle button {
    background-color: #333436 !important;
    color: white;

}


.darkTheme .mobiletabssubtitle {
    color: white;

}

.darkTheme .mobiletabstitle {
    color: white;

}

.darkTheme .bgcoloroflastcomponent {
    background-color: #333436 !important;
    color: white;
}