@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Work+Sans:wght@400;500;600;700;800&display=swap');

body {
    font-family: 'Poppins';
}

div {
    font-family: 'Poppins';
}

span {
    font-family: 'Poppins';
}

p {
    font-family: 'Poppins';
}

a {
    font-family: 'Poppins';
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.hausratBlueDiv {
    background-color: #30313A;
    border-radius: 16px;
    position: relative;
}

.hausratBlueDiv span {
    font-weight: 600;
    color: #fff;
}


.hausratShadowDiv {
    background-color: white;
    box-shadow: 0px 4px 18px rgba(170, 170, 170, 0.25);
    border-radius: 16px;

}

.hausratFormInputs_2 {
    background: #FFFFFF !important;
    border: 1px solid #D5D5D5 !important;
    border-radius: 11px !important;
    outline: none !important;
    padding: 0.6rem !important;
    width: 100%;
    box-shadow: none !important;
}

.hausratFormInputs_2::placeholder {
    font-weight: 500 !important;
}

.hausratFormInputs {
    width: 100%;
    background: #FFFFFF;
    border-radius: 11px;
    border: none;
    outline: none !important;
    padding: 0.6rem;
}

.hausratModalInputs {
    width: 100%;
    border-radius: 11px !important;
    border: none !important;
    outline: none !important;
    padding: 0.5rem !important;
}


.hausratRangeInput::-webkit-slider-runnable-track {
    -webkit-appearance: none !important;
    appearance: none !important;
    background: #fff !important;
    width: 100%;
    cursor: pointer;
    border-radius: 26px !important;
    height: 16px !important;
    border: 1px solid #D5D5D5 !important;
}

.hausratRangeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    background: #11A3E6;
    cursor: pointer;
    margin-top: -3px !important;
}

.hausratRangeInput::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #11A3E6;
    cursor: pointer;
}

.hausratBtn {
    background: #30313A;
    border-radius: 8px;
    border: none;
    width: 300px;
    color: #fff;
    font-weight: 800;
}

.blueBorderDiv {
    border: 1px solid #30313A;
    border-radius: 16px;
}

.blackTextBig {
    font-size: 60px;
    font-weight: 600;
}

.blueTextBig {
    font-size: 140px;
    font-weight: 800;
    color: #348ccc;
}

.crookedText {
    line-height: 1;
    transform: rotateZ(-15deg);
}

.hausratWhiteBg {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(166, 165, 165, 0.25);
    border-radius: 10px;
}

.blueBlobBg {
    background-image: url('../../assets/images/blueBlob.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 800;

}

.hausratSwiper {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hausratSwiper .swiper-pagination-bullet-active {
    background-color: #000;
}

.greenText {
    color: green;
}

.redText {
    color: red;
}

.hausratModal {
    background: rgba(29, 32, 34, 0.4);
    backdrop-filter: blur(15px);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.hausratModalContent {
    background: #fff;
    border-radius: 23px;
    width: 600px;
    position: relative;
    max-height: 100vh;
    overflow-y: hidden;
}

.modalXBtn {
    position: absolute;
    top: 1rem;
    right: 1.2rem;
}

.hausratModalScroll {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.checkmarkModalImgDiv img {
    width: 22px !important;
}

.festhypothekImgs svg {
    max-width: 270px;
}

.useFullLinks {
    box-shadow: 0px 4px 15px rgba(146, 146, 146, 0.25);
    color: #000;
    border-radius: 12px;
    height: 100%;
    display: flex;
}
.useFullLinksTitle {
    font-weight: 600;
}
.svitMarginLeft {
    margin-left: -90px;
}
.useFullLinks svg {
    height: 50px;
}
.linkSpan {
    font-size: 14px;
    color: #fff;
}

/* Drin Changes */
.updatedhypoteklargebox {
    max-width: 272px;
    height: 231px  ;
}
.boxoutline {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(146, 146, 146, 0.25);
    border-radius: 35px;

}
.updatedhypotekboxes {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(146, 146, 146, 0.25);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    max-height: 195px ;
}


@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
    .svitMarginLeft {
        margin-left: -60px;
    }
}

@media (max-width: 991.98px) {
    .hausratModalContent {

        max-height: 90vh;

    }

    .hausratModalScroll {
        max-height: 80vh;

    }
    .svitMarginLeft {
        margin-left: -70px;
    }
}

@media (max-width: 767.98px) {


    .blackTextBig {
        font-size: 50px;
    }

    .blueTextBig {
        font-size: 100px;

    }
    .svitMarginLeft {
        margin-left: -50px;
    }
}

@media (max-width: 575.98px) {


    .hausratBtn {
        width: 100%;
    }

    .blackTextBig {
        font-size: 30px;
    }

    .blueTextBig {
        font-size: 80px;

    }

    .hausratModalContent {

        max-height: 80vh;

    }

    .hausratModalScroll {
        max-height: 70vh;

    }
    .svitMarginLeft {
        margin-left: -60px;
    }
    .useFullLinksTitle {
        font-weight: 500;
    }
}

.darkTheme .hausratFormInputs {
    background-color: transparent;
    border: 1px solid #30313A;
    color: #fff;
}

.darkTheme .blueBorderDiv {
    color: #fff;
}

.darkTheme .hausratModalContent {
    background-color: #333436;
    color: #fff;
}

.darkTheme .hausratModalContent span {
    color: #fff !important;

}

/* .darkTheme .hausratModalContent input {
    border: 1px solid #434343 !important;
    background-color: transparent !important;
    color: #fff;
} */

/* .darkTheme .hausratModalContent select {
    border: 1px solid #434343 !important;
    background-color: transparent !important;
    color: #fff;
}

.darkTheme .hausratModalContent select option {
    color: #000;
} */

.darkTheme .hausratModalContent input:disabled {
    background-color: #282424 !important;
    color: #ccc;
}

/* .darkTheme .hausratModalContent select {
    border: 1px solid #434343 !important;
} */



.darkTheme .checkmarkRes2 {
    background-color: transparent;
    border: 1px solid #434343;
}
.darkTheme .checkmarkRes2.greyCheckMarkBorder {
    background-color: transparent;
    border: 1px solid #434343;
}
.darkTheme .hausratWhiteBg {
    background-color: transparent;
    box-shadow: 0px 4px 17px rgba(000, 000, 000, 0.5);
    color: #fff;
}

.darkTheme .blackTextBig {
    color: #fff;
}

.darkTheme .hausratSwiper .swiper-pagination-bullet-active {
    background-color: #fff;
}

.darkTheme .hausratShadowDiv {
    background-color: #333436;
    box-shadow: none;
}

.darkTheme .hausratFormInputs_2 {
    background-color: transparent !important;
    color: #fff !important;
}

.darkTheme .useFullLinks {
    background-color: #333436;
    color: #fff;
    box-shadow: unset;
}
.darkTheme .krankenInputStyle.krankenInputStepStyle.p-2 input {
    border: none !important;
} 
.darkTheme .modalXBtn svg path { 
    stroke: white;
}